import { scrollStop } from 'common/utils';
import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';
import {
  containsRect,
  scrollTabListTo,
  setSliderButtonVisibility,
  tabListHorizontalScrollBuffer,
  getSelectedTabFromTabList,
} from '../SduiTabbedGrid.helpers';

const useScrollOnStop = (
  tabListRef: RefObject<HTMLUListElement>,
  setShowPrevSliderButton: Dispatch<SetStateAction<boolean>>,
  setShowNextSliderButton: Dispatch<SetStateAction<boolean>>
) => {
  // Implement scroll tracking functionality to run code when scrolling has stopped.
  useEffect(() => {
    const tabListElement = tabListRef.current;
    if (tabListElement) {
      /*
        Checks if newly selected tab needs to be scrolled into view.
        Runs when user has stopped scrolling to avoid unnecessary scroll jacking.
      */
      scrollStop(() => {
        const selectedTab = getSelectedTabFromTabList(tabListElement);

        if (!selectedTab) return;

        const tabListElementRect = tabListElement.getBoundingClientRect();
        const selectedTabRect = selectedTab.getBoundingClientRect();
        const isSelectedTabVisible = containsRect(
          tabListElementRect,
          selectedTabRect,
          tabListHorizontalScrollBuffer
        );

        if (isSelectedTabVisible) return;

        scrollTabListTo(tabListElement, selectedTab, tabListHorizontalScrollBuffer);
      });

      /*
        Checks if slider buttons need to be shown or hidden based on the position of the tab list.
        Runs when user has stopped scrolling to avoid unnecessary scroll jacking.
      */
      scrollStop(
        () => setSliderButtonVisibility(tabListElement, setShowPrevSliderButton, setShowNextSliderButton),
        tabListElement
      );
    }
  }, [tabListRef, setShowPrevSliderButton, setShowNextSliderButton]);
};

export default useScrollOnStop;
