import styled from 'styled-components';

export const Heading1 = styled.h1`
  font-size: var(--h1-size);
`;

export const Heading2 = styled.h2`
  font-size: var(--h2-size);
`;

export const Heading3 = styled.h3`
  font-size: var(--h3-size);
`;

export const Heading4 = styled.h4`
  font-size: var(--h4-size);
`;

export const Heading5 = styled.h5`
  font-size: var(--h5-size);
`;

export const Heading6 = styled.h6`
  font-size: var(--h6-size);
`;
