import styled, { keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';
import { StyledSpan } from 'components/sduiInlineTextAndIconElement/SduiInlineTextAndIconElement';
import { SduiMetadataElementSection } from 'components/sduiMetadataElement/SduiMetadataElement';

const CardSkeleton = () => (
  <Card className="primary-card-view">
    <CardImageContainer />
    <CardSkeletonTitle>&nbsp;</CardSkeletonTitle>
  </Card>
);

export default CardSkeleton;

const CardAnimtation = keyframes`
  0% {
    opacity:10%;
    background-color: var(--dark-grey-transparent)
  }
  100% {
    opacity:30%;
    background-color: var(--dark-grey) 
  }
`;

const CardPrimaryTitle = styled.div`
  display: inline-block;
  padding-top: var(--space-xxsmall);

  ${StyledSpan} {
    vertical-align: middle;
    color: var(--almost-white);
    font-size: var(--base-font-size-large);
    margin-left: 0;
  }

  ${up('desktop')} {
    ${SduiMetadataElementSection} {
      ${StyledSpan} {
        font-size: 0.875rem;
      }
      margin-bottom: 0;
    }
    padding-top: var(--space-xsmall);
    position: relative;
  }
`;

const CardSkeletonTitle = styled(CardPrimaryTitle)`
  font-size: var(--base-font-size-large);
  padding-top: var(--space-xxsmall);
  margin-bottom: var(--space-xsmall);

  ${up('desktop')} {
    font-size: 0.875rem;
    margin-bottom: 0;
    padding-top: var(--space-xsmall);
  }
`;

const CardImageContainer = styled.div`
  animation: ${CardAnimtation} 1s linear infinite alternate;
  aspect-ratio: 16/9; // Applying aspect ratio so the card image area is always right, even when there is no image
  border-radius: var(--space-small);
  box-sizing: border-box;
  color: var(--almost-white);
  display: block;
`;

const Card = styled.article`
  width: 100%;
`;
