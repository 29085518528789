import styled, { css, keyframes } from 'styled-components';
import { VideoJsPlayer } from 'video.js';
import { down, up } from 'styled-breakpoints';
import BrightcovePlayer, { Tracking } from 'components/brightcovePlayer/brightcovePlayer';
import { useRef, useState } from 'react';
import Unmute from 'public/assets/unmute.svg';
import Mute from 'public/assets/mute.svg';
import { SSOState, configAtom, userLocationAtom } from 'src/store/store';
import { useAtomValue } from 'jotai';
import { isMobileOrTablet } from 'common/browser';
import { BackgroundVideo, DesktopImageType, MobileImageType } from 'common/types/types';
import { useSessionTrackingId } from 'hooks/useSessionTrackingId';
import usePauseOnLeave from 'components/brightcovePlayer/hooks/usePauseOnLeave';
import useUser from 'hooks/sso/useUser';
import { isNotNull } from 'common/utils';

// Please note that videoComponent.widget will not be implemented by 9Now Web.
// It will only be used by CTV.

export type Player = VideoJsPlayer;

export type SduiVideoType = {
  video: BackgroundVideo;
};

const SduiVideo = ({ video }: SduiVideoType) => {
  const { sessionId, resetSession } = useSessionTrackingId(video.tracking.channelName);
  const appConfig = useAtomValue(configAtom);
  const userLocation = useAtomValue(userLocationAtom);
  const [user] = useUser();
  const userNuid = user.type === SSOState.AUTHENTICATED ? user.decodedToken.nuid : null;
  const isUserOverseas = !userLocation || userLocation === 'overseas';

  const tracking: Tracking = {
    userNuid,
    sessionId,
    resetSession,
    channelName: video.tracking.channelName,
    mediaId: video.tracking.mediaId,
    mediaType: video.tracking.mediaType,
    publisherId: video.tracking.publisherId,
    streamPage: 'home_page',
  };

  const playerRef = useRef<Player | null>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoReady, setVideoReady] = useState(false);

  usePauseOnLeave(playerRef.current);

  const onInitHandler = (player: Player) => {
    playerRef.current = player;
    playerRef.current.volume(0);
  };

  const handleCanPlay = () => {
    setVideoReady(!isUserOverseas);
  };

  const onClickHandlerMuteButton = (): void => {
    if (!playerRef.current) {
      return;
    }

    setIsMuted((prevIsMuted) => {
      const newIsMuted = !prevIsMuted;

      if (isNotNull(playerRef.current)) {
        // Update the player's muted state and volume
        playerRef.current.muted(newIsMuted);
        playerRef.current.volume(newIsMuted ? 0 : 1);
      }

      return newIsMuted;
    });
  };

  return (
    <VideoBackgroundSection aria-hidden="true" data-testid="background-video">
      {appConfig && !isUserOverseas && video.liveChannelStream && (
        <StyledBrightcovePlayer
          onInit={onInitHandler}
          onCanPlay={handleCanPlay}
          isReady={isVideoReady}
          accountId={appConfig.livePlayer.accountId}
          playerId={appConfig.livePlayer.playerId}
          controls={false}
          streamUrl={video.liveChannelStream}
          videoType="live"
          tracking={tracking}
          options={{
            // autoplay the video on load
            // https://player.support.brightcove.com/playback/autoplay-considerations.html
            autoplay: 'muted',
          }}
        />
      )}

      <BackgroundImage
        mobileImageType={video.mobileImage}
        desktopImageType={video.desktopImage}
        isVideoReady={isVideoReady}
      />
      <VideoBackgroundOverlay />
      {isVideoReady ? (
        <MuteButton onClick={onClickHandlerMuteButton} isTouchDevice={isMobileOrTablet()}>
          {isMuted ? (
            <Mute role="img" aria-label="mute video" />
          ) : (
            <Unmute role="img" aria-label="unmute video" />
          )}
        </MuteButton>
      ) : null}
    </VideoBackgroundSection>
  );
};

export default SduiVideo;

const StyledBrightcovePlayer = styled(BrightcovePlayer)<{ isReady: boolean }>`
  /* This is following design guidelines https://nine.atlassian.net/wiki/spaces/9NOW/pages/9007923225/Video+component */
  aspect-ratio: 16/9;
  height: 100%;
  animation-name: ${({ isReady }) => (isReady ? videoFadeIn : videoFadeOut)};
  // 700ms is chosen here as it accounts for the 400ms fade to black and 300ms fade to video as per original designs = https://nine.atlassian.net/browse/VIDEOWEB-2506
  animation-duration: 700ms;
  opacity: ${({ isReady }) => (isReady ? 1 : 0)};
`;

const videoFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const videoFadeOut = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;

const VideoBackgroundOverlay = styled.div`
  /* This is following design guidelines https://nine.atlassian.net/wiki/spaces/9NOW/pages/9007923225/Video+component */
  background: linear-gradient(to top, var(--gradient-black-bezier)) bottom/100% 25% no-repeat;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    background: linear-gradient(to top, var(--gradient-black-bezier)) bottom/100% 50% no-repeat,
      linear-gradient(to right, var(--gradient-black-bezier)) left/125% 100% no-repeat;
  }
`;

const VideoBackgroundSection = styled.section`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  position: relative;
`;

const MuteButton = styled.button<{ isTouchDevice: boolean }>`
  align-items: center;
  background-color: var(--dark-grey-half-transparent);
  border-radius: 3.438rem 0 0 3.438rem;
  border: none;
  bottom: 18rem;
  display: flex;
  height: 2.813rem;
  justify-content: flex-start;
  padding: 0;
  position: absolute;
  right: 0;
  width: 5.1565rem;
  z-index: var(--z-index--header_and_background_container--background_video--mute_button);

  /* We are using the after pseudo-element to create the grey circle of the button that moves with the button for design purposes. */
  ::after {
    background-color: var(--dark-grey);
    border-radius: 50%;
    content: ' ';
    height: 2.813rem;
    position: absolute;
    width: 2.813rem;
    z-index: var(--z-index--header_and_background_container--background_video--mute_button--background);
  }

  > svg {
    color: var(--almost-white);
    height: 2.813rem;
    padding: 0.7815rem;
    width: 2.813rem;
  }

  ${({ isTouchDevice }) =>
    !isTouchDevice &&
    css`
      :hover {
        ::after {
          background-color: var(--almost-white);
        }
        > svg {
          color: var(--almost-black);
        }
      }
    `}

  ${down('tabletPortrait')} {
    right: -1.5rem;
    top: 5.625rem;
  }
`;

const imageFadeIn = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

const imageFadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const BackgroundImage = styled.figure<{
  mobileImageType: MobileImageType;
  desktopImageType: DesktopImageType;
  isVideoReady: boolean;
}>`
  // Use 4:5 mobile fallback image for breakpoints up to 900px.
  background-image: url('/assets/bg-image-9now-default-768x1024-@1x.jpg');
  ${(p) =>
    p.mobileImageType &&
    `background-image: url('${p.mobileImageType.webp.w768.url}'),url('${p.mobileImageType.default.w768.url}'),url('/assets/bg-image-9now-default-768x1024-@1x.jpg');`}
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  width: 100%;
  position: absolute;
  animation-name: ${({ isVideoReady }) => (isVideoReady ? imageFadeOut : imageFadeIn)};
  animation-duration: 400ms;
  opacity: ${({ isVideoReady }) => (isVideoReady ? 0 : 1)};

  ${up('tabletPortrait')} {
    background-image: url('/assets/bg-image-9now-default-768x1024-@1x.jpg');
    ${(p) =>
      p.mobileImageType &&
      `background-image: url('${p.mobileImageType.webp.w768.url}'),url('${p.mobileImageType.default.w768.url}'),url('/assets/bg-image-9now-default-768x1024-@1x.jpg');
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
          background-image: url('${p.mobileImageType.webp.w1920.url}'),url('${p.mobileImageType.default.w1920.url}'),url('/assets/bg-image-9now-default-1920x1080-@1x.jpg');
      }
    `}
  }

  // Use the normal background image for breakpoints of 900px and above.
  ${up('tabletLandscape')} {
    background-image: url('/assets/bg-image-9now-default-768x1024-@1x.jpg');
    ${(p) =>
      p.desktopImageType &&
      `background-image: url('${p.desktopImageType.webp.w1280.url}'),url('${p.desktopImageType.default.w1280.url}'),url('/assets/bg-image-9now-default-768x1024-@1x.jpg');
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
          background-image: url('${p.desktopImageType.webp.w1920.url}'),url('${p.desktopImageType.default.w1920.url}'),url('/assets/bg-image-9now-default-1920x1080-@1x.jpg');
      }
    `}
  }
`;
