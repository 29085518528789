'use client';

import styled from 'styled-components';
import { GraphQlResponse, HomePageQuery } from 'common/queries';
import LoadingSkeleton from 'components/loadingSkeleton/LoadingSkeleton';
import useUser from 'hooks/sso/useUser';
import { UnstyledList } from 'styles/styledComponents';
import { ssoFeatureFlagAtom } from 'src/store/store';
import { useSetAtom } from 'jotai';
import Layout from '../../components/layout/Layout';

type Props = {
  data: GraphQlResponse<HomePageQuery>;
  isEnabled?: boolean;
};

const Home = ({ data, isEnabled = false }: Props) => {
  const [user, isLoggedIn] = useUser();
  const setSSOFlagAtom = useSetAtom(ssoFeatureFlagAtom);
  setSSOFlagAtom(isEnabled);

  return (
    <>
      <HiddenHeadingTag>9Now - Watch Channel 9 Live and On-demand</HiddenHeadingTag>
      <SkipLinksNav aria-label="skip links menu">
        <UnstyledList>
          <li>
            <a href="#hero">Skip to Live program</a>
          </li>
          <li>
            <a href="#tab_list">Skip to catalogue</a>
          </li>
        </UnstyledList>
      </SkipLinksNav>
      <Layout fullWidth userFirstName={user.settings?.firstName} isLoggedIn={isLoggedIn}>
        <LoadingSkeleton loggedOutLayout={data.data.getHomePage} />
      </Layout>
    </>
  );
};

export default Home;

/* The H1 tag improves the SEO of the website. To maintain the aesthetics of the page, we have opted to make it hidden,
despite a slight SEO penalty for hiding it. A hidden H1 tag is better than no tag at all. */

const HiddenHeadingTag = styled.h1`
  font-size: 10px;
  overflow: hidden;
  position: absolute;
  text-indent: 100%;
  white-space: nowrap;
  z-index: var(--z-index--hidden_h1);
`;

const SkipLinksNav = styled.nav`
  background-color: var(--almost-black);
  left: 0;
  padding: 0 1rem;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  z-index: var(--z-index--skip-links-nav);
  ul {
    align-items: center;
    display: flex;
    gap: 2rem;
    height: 2rem;
  }
  a {
    color: var(--almost-white);
    &:hover,
    &:focus {
      color: var(--dark-turquoise);
    }
  }
  &:focus-within {
    transform: translateY(0%);
  }
  &:focus-within ~ .layout__container {
    transform: translateY(2rem);
  }
`;
