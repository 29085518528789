import useUser from 'hooks/sso/useUser';
import { SSOState, configAtom, ssoFeatureFlagAtom, userLocationAtom } from 'src/store/store';
import useSWR from 'swr';
import useToken from 'hooks/sso/useToken';
import useSnowplow from 'hooks/useSnowplow';
import { useSwrApiFetcher } from 'common/utils/swr.util';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { err } from 'common/utils';
import { getStreamParams } from 'common/browser';
import { formatQueryParameters } from 'common/queries';
import { Layout } from 'common/types/graphQlTypes';
import SduiLayout from 'components/sduiLayout/SduiLayout';
import styled, { keyframes } from 'styled-components';
import { up } from 'styled-breakpoints';
import { headerAndBackgroundContainerMixin, headerOverlayMixin } from '../StyledComponents/StyledComponents';

type Props = {
  loggedOutLayout: Layout;
};

const SduiLayoutLoadingSkeleton = ({ loggedOutLayout }: Props) => {
  const [user] = useUser();
  const appConfig = useAtomValue(configAtom);
  const userLocation = useAtomValue(userLocationAtom);
  const ssoFeatureFlag = useAtomValue(ssoFeatureFlagAtom);
  const encodedToken = useToken();
  const userNuid = user.type === SSOState.AUTHENTICATED && user.decodedToken.nuid;
  // We want the encodedToken to only update when the NUID changes, hence not including it in the below dependency array.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedToken = useMemo(() => (ssoFeatureFlag && encodedToken) || null, [userNuid, ssoFeatureFlag]);
  const queryParams = {
    region: userLocation,
    device: 'web',
    token: memoizedToken,
    queryFlagsArgs: encodeURIComponent(
      JSON.stringify({
        flags: {
          enableDynamicTabs: 'true',
        },
      })
    ),
    streamParams: getStreamParams(),
  };
  const { data, error, isLoading } = useSWR(
    appConfig && userLocation && `${appConfig.apiUrl}/web/home-page?${formatQueryParameters(queryParams)}`,
    useSwrApiFetcher
  );

  const loggedInLayout = data?.data?.getHomePage as Layout;

  if (user.type === SSOState.RUNTIME_ERROR) window.location.assign('/500');

  useEffect(() => {
    if (error) {
      err(error);
    }
  }, [error]);

  const showLoading =
    userLocation === null ||
    isLoading ||
    user.type === SSOState.LOADING ||
    (user.type === SSOState.AUTHENTICATED && loggedInLayout === null);

  useSnowplow(user, loggedInLayout ?? loggedOutLayout);

  return <ControlledLoadingSkeleton layout={loggedInLayout ?? loggedOutLayout} isLoading={showLoading} />;
};

export default SduiLayoutLoadingSkeleton;

type ControlledProps = {
  layout: Layout;
  isLoading: boolean;
};

// This component allows us to control its state through props. This makes it easier to test
// the Loading Skeleton, relying less on the use of mocks, and it makes it easier to create
// Storybook controls for the component.
export const ControlledLoadingSkeleton = ({ layout, isLoading }: ControlledProps) =>
  isLoading ? (
    <SkeletonContainer data-testid="loadingSkeleton">
      <HeroSkeletonContainer>
        <HeroSkeleton />
      </HeroSkeletonContainer>

      <TabbedGridSkeletonContainer>
        <TabbedGridSkeleton />
      </TabbedGridSkeletonContainer>
    </SkeletonContainer>
  ) : (
    <SduiLayout layout={layout} />
  );

const gradientAnimation = keyframes`
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedSkeleton = styled.div`
  /* The linear gradient has extra #1f2427 steps so that there is a smooth transition when the animation restarts */
  /* These colours are not CSS variables because they are one-offs. If you copy them them, convert them into a variable first. */
  background-image: linear-gradient(-45deg, #1f2427, #1f2427, #1f2427, #252c30, #1f2427, #1f2427, #1f2427);
  background-origin: content-box;
  background-size: 800%;
  animation: ${gradientAnimation} 3s linear infinite;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeroSkeletonContainer = styled.div`
  ${headerAndBackgroundContainerMixin}
  padding: var(--space-content-large);
`;

const HeroSkeleton = styled(AnimatedSkeleton)`
  ${headerOverlayMixin}
  margin: 0 auto;
  height: 265px;
  width: 306px;
  -webkit-mask-image: url(/assets/hero-loading-skeleton-centred.svg);
  mask-image: url(/assets/hero-loading-skeleton-centred.svg);

  ${up('tabletLandscape')} {
    height: 437px;
    width: 552px;
    -webkit-mask-image: url(/assets/hero-loading-skeleton-left-aligned.svg);
    mask-image: url(/assets/hero-loading-skeleton-left-aligned.svg);
    margin-left: var(--space-content-large);
  }

  // phone landscape
  // On widths below 999px, if the viewport is landscape, the video should take 100% of the width
  @media ((max-width: 999px) and (orientation: landscape)) {
    margin: 0 auto;
    height: 265px;
    width: 306px;
    -webkit-mask-image: url(/assets/hero-loading-skeleton-centred.svg);
    mask-image: url(/assets/hero-loading-skeleton-centred.svg);
  }

  ${up('desktopXLarge')} {
    width: 34.5vw;
    height: unset;
    /* This aspect ratio is determined by the width and height of the related svg file. */
    aspect-ratio: 552 / 437;
  }
`;

const TabbedGridSkeletonContainer = styled.div`
  padding: 4rem 9vw 0 9vw;
  position: relative;

  ${up('tabletPortrait')} {
    top: -2rem;
    margin-bottom: -2rem;
  }

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    padding: var(--space-content-large);
    top: -7rem;
    margin-bottom: -7rem;
  }

  @media (max-height: 800px) {
    top: -2rem;
    margin-bottom: -2rem;
  }

  @media (max-height: 500px) {
    top: 0;
  }

  ${up('desktop')} {
    top: -7rem;
    margin-bottom: -7rem;
  }

  ${up('desktopLarge')} {
    top: -14rem;
    margin-bottom: -14rem;
  }
`;

const TabbedGridSkeleton = styled(AnimatedSkeleton)`
  @media (max-width: 999px) {
    margin: 0 auto;
  }

  ${up('tabletPortrait')} {
    height: 211px;
    width: 520px;
    -webkit-mask-image: url(/assets/tabbed-grid-loading-skeleton-tablet.svg);
    mask-image: url(/assets/tabbed-grid-loading-skeleton-tablet.svg);
  }

  ${up('desktop')} {
    height: 246px;
    width: 918px;
    -webkit-mask-image: url(/assets/tabbed-grid-loading-skeleton-desktop.svg);
    mask-image: url(/assets/tabbed-grid-loading-skeleton-desktop.svg);
  }

  ${up('desktopXLarge')} {
    height: unset;
    width: 82vw;
    aspect-ratio: 1575 / 218;
    -webkit-mask-image: url(/assets/tabbed-grid-loading-skeleton-desktop-xlarge.svg);
    mask-image: url(/assets/tabbed-grid-loading-skeleton-desktop-xlarge.svg);
  }
`;
