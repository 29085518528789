import { warn } from 'common/utils';
import {
  ButtonsElement,
  DescriptionElement,
  ElementType,
  HeroElement,
  MetadataElement,
  TitleElement,
} from 'src/common/types/graphQlTypes';
import SduiTitleElement from 'components/sduiTitleElement/SduiTitleElement';
import SduiDescriptionElement from 'components/sduiDescriptionElement/SduiDescriptionElement';
import SduiMetadataElement from '../sduiMetadataElement/SduiMetadataElement';
import SduiButtonsElement from '../sduiButtonsElement/SduiButtonsElement';

const SduiHeroElement = ({ heroElement }: { heroElement: HeroElement }) => {
  if (heroElement.type === ElementType.Title) {
    return <SduiTitleElement className="sdui_hero_element" title={heroElement as TitleElement} />;
  }
  if (heroElement.type === ElementType.Description) {
    return (
      <SduiDescriptionElement
        className="sdui_hero_element"
        descriptionElement={heroElement as DescriptionElement}
      />
    );
  }
  if (heroElement.type === ElementType.Metadata) {
    return (
      <SduiMetadataElement className="sdui_hero_element" metadataElement={heroElement as MetadataElement} />
    );
  }
  if (heroElement.type === ElementType.Buttons) {
    return (
      <SduiButtonsElement
        className="sdui_hero_element sdui_hero_element_buttons"
        buttonsElement={heroElement as ButtonsElement}
      />
    );
  }
  warn(`Unknown HeroElement type: ${heroElement.type}`);
  return null;
};

export default SduiHeroElement;
