import { Component, ComponentType, Hero, TabbedGrid } from 'src/common/types/graphQlTypes';
import SduiHero from 'components/sduiHero/SduiHero';
import SduiVideo from 'components/sduiVideo/SduiVideo';
import SduiTabbedGrid from 'components/sduiTabbedGrid/SduiTabbedGrid';
import { warn } from 'common/utils';
import { BackgroundVideo } from 'common/types/types';

const SduiComponent = ({ component }: { component: Component }) => {
  if (component.componentType === ComponentType.Hero) {
    return <SduiHero hero={component as Hero} />;
  }
  if (component.componentType === ComponentType.Video) {
    return <SduiVideo video={component as BackgroundVideo} />;
  }
  if (component.componentType === ComponentType.TabbedGrid) {
    return <SduiTabbedGrid tabbedGrid={component as TabbedGrid} />;
  }
  warn(`"${component.componentType}" is not recognised as a valid component type.`);
  return null;
};

export default SduiComponent;
