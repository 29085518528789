'use client';

import Link from 'next/link';
import ErrorPage from 'src/components/errorPage/ErrorPage';

export default function Custom404() {
  return (
    <ErrorPage
      heading="This page doesn't exist"
      text=""
      bottomText={
        <>
          For more great content, head back to the <Link href="/">9Now homepage</Link>.
        </>
      }
    />
  );
}
