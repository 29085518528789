import { v4 as uuid } from 'uuid';
import { useEffect, useMemo, useRef, useState } from 'react';

export const useSessionTrackingId = (channelName: string) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sessionId = useMemo(() => uuid(), [channelName]);
  // We use string "true" or "false" instead of booleans because that's what the plugin requires
  const [resetSession, setResetSession] = useState('true');

  // keep track of the previous session id
  const prevSessionId = useRef('');

  // compare the prev and current session id. If they are different, tell the session to reset
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const shouldResetSession = prevSessionId.current !== sessionId ? 'true' : 'false';

    // only update the resetSession state is it's different - this avoids re-rendering if the value is `false` after the session id stabilizes
    if (shouldResetSession !== resetSession) {
      setResetSession(shouldResetSession);
    }

    prevSessionId.current = sessionId;
  });

  return {
    sessionId,
    resetSession,
  };
};
