import { Layout, LayoutTypes, StandardLayout, VideoBackgroundLayout } from 'src/common/types/graphQlTypes';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import SduiComponent from '../sduiComponent/SduiComponent';
import { headerAndBackgroundContainerMixin, headerOverlayMixin } from '../StyledComponents/StyledComponents';

type Props = {
  layout: Layout;
};

const SduiLayout = ({ layout }: Props) => {
  if (layout.layoutType === LayoutTypes.StandardLayout) {
    const standardLayout = layout as StandardLayout;
    return (
      <>
        <SduiComponent component={standardLayout.sections.header} />
        <SduiComponent component={standardLayout.sections.content} />
      </>
    );
  }
  if (layout.layoutType === LayoutTypes.VideoBackgroundLayout) {
    const videoBackgroundLayout = layout as VideoBackgroundLayout;
    return (
      <>
        <HeaderAndBackgroundContainer>
          <BackgroundVideo>
            <SduiComponent component={videoBackgroundLayout.sections.background} />
          </BackgroundVideo>
          <HeaderOverlay>
            <SduiComponent component={videoBackgroundLayout.sections.header} />
          </HeaderOverlay>
        </HeaderAndBackgroundContainer>
        <SduiComponent component={videoBackgroundLayout.sections.content} />
      </>
    );
  }
  throw new Error(`Unknown layoutType: ${layout.layoutType}`);
};

const HeaderAndBackgroundContainer = styled.section`
  ${headerAndBackgroundContainerMixin}
`;

const BackgroundVideo = styled.section`
  /* This is following design guidelines https://nine.atlassian.net/wiki/spaces/9NOW/pages/9007923225/Video+component */

  /* We use an aspect ratio so that the height of the video is slightly bigger than the width, no matter what the width is. */
  aspect-ratio: 4/5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: var(--z-index--header_and_background_container--background_video);

  ${up('tabletPortrait')} {
    aspect-ratio: unset;
    height: 90vh;
  }

  // phone landscape
  // On widths below 999px, if the viewport is landscape, the video should take 100% of the width
  @media ((max-width: 999px) and (orientation: landscape)) {
    aspect-ratio: 16/9;
    height: unset;
  }

  ${up('tabletLandscape')} {
    max-height: 1200px;
  }

  ${up('desktop')} {
    /* 16/9 is a standard aspect ratio for videos. We use it to keep our video a standard width and height while still being responsive. */
    aspect-ratio: 16/9;
    height: unset;
    max-height: unset;
  }
`;

const HeaderOverlay = styled.section`
  ${headerOverlayMixin}
`;

export default SduiLayout;
