import { RefObject, useEffect } from 'react';
import {
  generateTabId,
  getSelectedPanelFromTabsElement,
  tabbedGridVerticalScrollBuffer,
} from '../SduiTabbedGrid.helpers';

const useScrollToTabOnLoad = (
  tabsElementRef: RefObject<HTMLElement>,
  tabListElementRef: RefObject<HTMLElement>,
  headerHeight: number
) => {
  useEffect(() => {
    const tabsElement = tabsElementRef.current;
    const tabListElement = tabListElementRef.current;

    if (!tabsElement || !tabListElement) return;

    const urlParams = new URLSearchParams(window.location.search);
    const selectedTabTitle = urlParams.get('selectedTab');

    if (!selectedTabTitle) return;

    const selectedTabPanel = getSelectedPanelFromTabsElement(tabsElement, generateTabId(selectedTabTitle));

    if (!selectedTabPanel) return;

    // Add a changeable buffer 'tabbedGridVerticalScrollBuffer' to this value to move the scroll position below the header background gradient.
    const tabListBottomPosition = headerHeight + tabListElement.clientHeight + tabbedGridVerticalScrollBuffer;
    const distanceToScrollBy = selectedTabPanel.getBoundingClientRect().top - tabListBottomPosition;
    window.scrollBy({ top: distanceToScrollBy, behavior: 'smooth' });
  }, [tabsElementRef, tabListElementRef, headerHeight]);
};

export default useScrollToTabOnLoad;
