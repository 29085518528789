import { useEffect } from 'react';
import { VideoJsPlayer } from 'video.js';

const usePauseOnLeave = (player: VideoJsPlayer | null) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      /**
       * Requesting animation frame to ensure the visibility state has changed before computing anything,
       * otherwise Brightcove script will throw an error in the console.
       */
      requestAnimationFrame(() => {
        if (document.hidden) {
          typeof player?.pause === 'function' && player.pause();
        } else {
          typeof player?.play === 'function' && player.play();
        }
      });
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [player]);
};

export default usePauseOnLeave;
