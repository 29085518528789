import { generateTabId, generateTabPanelId } from 'components/sduiTabbedGrid/SduiTabbedGrid.helpers';

type Props = {
  children: React.ReactNode;
  className: string;
  title: string;
};

const TabPanel = ({ className, title, children }: Props) => {
  const tabId = generateTabId(title);
  const tabPanelId = generateTabPanelId(title);
  return (
    <div aria-labelledby={tabId} className={className} id={tabPanelId} role="tabpanel" tabIndex={0}>
      {children}
    </div>
  );
};

export default TabPanel;
