import classNames from 'classnames';
import {
  generateTabId,
  generateTabPanelId,
  getSelectedPanelFromTabsElement,
} from 'components/sduiTabbedGrid/SduiTabbedGrid.helpers';
import './Tab.scss';

export type TabProps = {
  hasScrolled?: boolean;
  isSelected?: boolean;
  onClick: () => void;
  title: string;
  tabsRef?: React.RefObject<HTMLDivElement>;
};

const Tab = ({ isSelected = false, hasScrolled = false, title, onClick, tabsRef }: TabProps) => {
  const tabId = generateTabId(title);
  const tabPanelId = generateTabPanelId(title);
  const tabsElement = tabsRef?.current;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (!tabsElement) return;
      (getSelectedPanelFromTabsElement(tabsElement, tabId) as HTMLElement).focus();
    }
  };

  return (
    <li
      aria-controls={tabPanelId}
      aria-selected={isSelected}
      className={classNames('tab', { 'tab--selected': isSelected })}
      data-has-scrolled={hasScrolled}
      id={tabId}
      role="tab"
    >
      <button tabIndex={0} onKeyDown={handleKeyDown} onClick={onClick} type="button">
        {title}
      </button>
    </li>
  );
};

export default Tab;
