import '../button/button.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ActionType, Button, ButtonDisplayTypes, ChangePageAction } from 'common/types/graphQlTypes';
import { useSDK } from 'hooks/sso/useSDK';
import { createChangePageUrl } from 'components/button/SduiButton.utils';
import type { ButtonProps } from 'components/button/button';

type ButtonAsLinkProps = ButtonProps & {
  actions: Button['actions'];
};

const ButtonAsLink = ({
  title,
  icon,
  displayType,
  className,
  isCircular = false,
  actions,
  testId,
  ariaLabel,
}: ButtonAsLinkProps) => {
  const [linkHref, setLinkHref] = useState<string>('#'); // Initialize with a default placeholder
  const sdk = useSDK();

  useEffect(() => {
    const processLink = async () => {
      if (actions?.onClick?.length) {
        actions.onClick.forEach((action) => {
          if (action?.actionType === ActionType.ChangePage) {
            const changePageAction = action as ChangePageAction;
            const destination = createChangePageUrl(changePageAction);

            if (destination) {
              setLinkHref(destination);
            }
          }
        });
      }
    };

    processLink();
  }, [actions, sdk]);

  if (displayType === ButtonDisplayTypes.Hidden) {
    return null;
  }

  const hasText = Boolean(title);

  const buttonClassNames = `button--base button--styled ${
    displayType ? `button--styled--${displayType.toLowerCase()}` : ''
  } ${isCircular ? 'button--styled--is-circular' : ''} ${className || ''}`;

  const textClassNames = displayType
    ? `button--styled__text button--styled__text--${displayType.toLowerCase()}`
    : 'button--styled__text';

  return (
    <a
      data-testid={testId}
      href={linkHref}
      title={title}
      aria-label={ariaLabel}
      className={classNames(buttonClassNames)}
    >
      {icon}
      {hasText && !isCircular && <span className={textClassNames}>{title}</span>}
    </a>
  );
};

export default ButtonAsLink;
