import { useEffect, useRef } from 'react';
import { CardElement, FetchDataAction, Tab } from 'common/types/graphQlTypes';
import SduiCardElement from 'components/sduiCardElement/SduiCardElement';
import { Heading2 } from 'components/headings/Headings';
import styled from 'styled-components';
import { useFetchDynamicTab } from 'hooks/useFetchDynamicTab';
import { useIntersection } from 'hooks/useIntersection';
import { useSDK } from 'hooks/sso/useSDK';
import { up } from 'styled-breakpoints';
import { err } from 'common/utils';
import CardSkeleton from './CardSkeleton';

// We are getting the fetch-data action of a tab, verifying if the tab has a onLoad action of type fetch-data
const getDynamicTabFetchDataAction = (tab: Tab): FetchDataAction | null => {
  const fetchDataAction = tab.actions?.onLoad.find((action) => action?.actionType === 'FETCH_DATA');
  return fetchDataAction || null;
};

const StaticTab = ({ id, title, cards }: { id: string; title: string; cards: CardElement[] }) => {
  const sdk = useSDK();

  return (
    <section id={id}>
      <TabTitle className="tab_title">{title}</TabTitle>
      {cards && (
        <Grid>
          {cards.map((card) => (
            <SduiCardElement key={card.id} cardElement={card} sdk={sdk} />
          ))}
        </Grid>
      )}
    </section>
  );
};

export const DynamicTab = ({
  cardCount,
  variables,
  title,
  id,
}: {
  cardCount: number;
  title: string;
  id: string;
  variables: unknown;
}) => {
  const tabRef = useRef<HTMLDivElement>(null);
  const { data, error, isLoading, load } = useFetchDynamicTab(variables);
  // we are using this hook to know when the tabs are visible on the screen, so we can defer the API call until then
  const isVisible = useIntersection(tabRef);

  useEffect(() => {
    if (isVisible && !isLoading && !data && !error) {
      load();
    }
  }, [isVisible, load, data, error, isLoading]);

  if (error) {
    err(error);
  }

  if (isLoading || !data || error) {
    return (
      <div ref={tabRef}>
        <TabTitle className="tab_title">{title}</TabTitle>
        <Grid data-testid="dynamic-tabs-loading-skeleton">
          {/* We use the apply to create an array with the length of cards so we can iterate and render the skeleton cards */}
          {/* eslint-disable-next-line prefer-spread */}
          {Array.apply(null, Array(cardCount)).map((_, index) => (
            <CardSkeleton key={`${id}_${index}`} />
          ))}
        </Grid>
      </div>
    );
  }

  const {
    data: {
      getElementById: { cards },
    },
  } = data;

  return <StaticTab id={id} title={title} cards={cards} />;
};

const SduiTab = ({ tab }: { tab: Tab }) => {
  const { cardCount, title, id, cards } = tab;

  const fetchDataAction = getDynamicTabFetchDataAction(tab);
  const isDynamicTab = !!fetchDataAction;

  if (!cardCount || cardCount === 0) {
    return null;
  }

  if (isDynamicTab) {
    return <DynamicTab id={id} title={title} variables={fetchDataAction.variables} cardCount={cardCount} />;
  }

  return <StaticTab id={id} title={title} cards={cards as CardElement[]} />;
};

export default SduiTab;

export const TabTitle = styled(Heading2)`
  color: white;
  font-size: var(--h5-size);
  margin: 0;
  padding: var(--space-small) 0;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: var(--space-small);
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));

  ${up('desktop')} {
    grid-gap: var(--space-xlarge) var(--space-medium);
    padding-bottom: var(--space-xsmall);
    grid-template-columns: repeat(4, 1fr);
  }

  /* Once we hit large desktop / TV with HDMI, we set the grid to always be 6 columns */
  ${up('desktopLarge')} {
    grid-template-columns: repeat(6, 1fr);
  }
`;
