import { useAtomValue, useSetAtom } from 'jotai';
import { RefObject, useEffect, useRef } from 'react';
import { headerAtom, tabbedGridAtom } from 'src/store/store';

const useTabListObserver = (tabListRef: RefObject<HTMLUListElement>) => {
  const { headerHeight } = useAtomValue(headerAtom);
  const setTabbedGridAtom = useSetAtom(tabbedGridAtom);
  const isTabListOnScreen = useRef<boolean>(false);

  // Determine whether the Tab List is fixed to the top of the screen using an observer.
  useEffect(() => {
    const tabListElement = tabListRef.current;

    if (!tabListElement) return;

    const tabListOnScreenObserver = new IntersectionObserver(
      ([e]) => {
        isTabListOnScreen.current = e.intersectionRatio > 1;
      },
      { threshold: [1] }
    );

    const tabListPinnedObserver = new IntersectionObserver(
      ([e]) => {
        isTabListOnScreen.current && setTabbedGridAtom({ isPositionFixed: e.intersectionRatio < 1 });
      },
      { threshold: [1], rootMargin: `-${headerHeight + 1}px 0px 0px 0px` }
    );

    tabListOnScreenObserver.observe(tabListElement);
    tabListPinnedObserver.observe(tabListElement);

    return () => {
      tabListOnScreenObserver.unobserve(tabListElement);
      tabListPinnedObserver.unobserve(tabListElement);
    };
  }, [headerHeight, setTabbedGridAtom, tabListRef]);
};

export default useTabListObserver;
