import { useState, useEffect, MutableRefObject } from 'react';

// this hook works as a interceptor observer to a given element ref. It will let the consumer know when the element is visible on the screen
export const useIntersection = (element: MutableRefObject<HTMLDivElement | null>) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const elementRef = element.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: '0px' }
    );

    elementRef && observer.observe(elementRef);

    return () => {
      elementRef && observer.unobserve(elementRef);
    };
  }, [element]);

  return isVisible;
};
