import {
  Action,
  Actions,
  ActionType,
  ChangePageAction,
  LocationType,
  Maybe,
  SnowplowEvent,
} from 'common/types/graphQlTypes';
import type SDK from '@nine-tech/sso-sdk';
import type WebSSODisabled from '@nine-tech/sso-sdk/lib/web-disabled';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { firstOrUndefined, warn } from 'common/utils';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { getStreamOffsetMinutes } from '../../app/live/[slug]/live-page.resource';

export const buttonOnClick =
  (actions: Maybe<Actions> | undefined, sdk: SDK | WebSSODisabled | null, router?: AppRouterInstance) =>
  async () => {
    const validationMessage = validateActions(actions);
    if (validationMessage) {
      warn(validationMessage);
    }
    if (!actions) return null;

    await Promise.all(actions.onClick.map((a) => processAction(a, sdk, router)));
  };

export async function processAction(
  action: Maybe<Action>,
  sdk: SDK | WebSSODisabled | null,
  router?: AppRouterInstance
) {
  const locationsGatedByAuth = [LocationType.Channel, LocationType.LiveEvent];
  if (!action) {
    return;
  }

  if (action.actionType === ActionType.ChangePage) {
    const changePageAction = action as ChangePageAction;
    const destination = createChangePageUrl(changePageAction);
    try {
      if (locationsGatedByAuth.includes(changePageAction.location.locationType)) await sdk?.getTokenAsync();
      // If we successfully retrieve the token, the user is logged in.
      router?.push(destination);
    } catch (e) {
      // The user is not logged in, so prompt them to log in first.
      window.location.assign(
        sdk?.getSSOPath({
          routeName: 'Login',
          state: {
            redirect_uri: destination,
          },
        })
      );
    }
  }

  if (action.actionType === ActionType.SnowplowEvent) {
    const snowplowEvent = action as SnowplowEvent;
    trackSelfDescribingEvent({
      event: snowplowEvent,
    });
  }
}

export function createChangePageUrl(changePageAction: ChangePageAction): string {
  const { locationType, slug, tvSeriesSlug, seasonSlug, startOver } = changePageAction.location;
  if (locationType === LocationType.Channel || locationType === LocationType.LiveEvent) {
    return `/live/${slug}?startPlaying=true${
      startOver ? `&stream-offset=${getStreamOffsetMinutes(startOver?.time)}` : ''
    }`;
  }
  if (locationType === LocationType.LongformWatch || locationType === LocationType.ShortformWatch) {
    return `/${tvSeriesSlug}/${seasonSlug}/${slug}`;
  }
  if (locationType === LocationType.TvSeriesPage) {
    return `/${tvSeriesSlug}`;
  }
  if (locationType === LocationType.TvSeasonPage) {
    return `/${tvSeriesSlug}/${seasonSlug}`;
  }
  if (locationType === LocationType.Genre) {
    return `/shows/${slug}`;
  }
  if (locationType === LocationType.UpcomingEvent) {
    return `/live/${slug}`;
  }
  warn(`"${locationType}" is not recognised as a valid location type.`);
  return '';
}

export function validateActions(actions?: Maybe<Actions>) {
  if (!actions) {
    return null;
  }

  const messages = actions.onClick.map((action) => {
    if (!action) {
      return null;
    }

    if (!Object.values(ActionType).includes(action.actionType)) {
      return `Unknown actionType: ${action.actionType}`;
    }

    if (action.actionType === ActionType.ChangePage) {
      const changePageAction = action as ChangePageAction;
      if (!Object.values(LocationType).includes(changePageAction.location.locationType)) {
        return `Unknown locationType: ${changePageAction.location.locationType}`;
      }
    }
    return null;
  });
  return firstOrUndefined(messages.filter((x) => x !== null));
}
