/**
 * @module resourceUtil
 * This module contains utility functions for working with API resources in the front-end.
 */
import { validateResponse } from '../queries';

export const useSwrApiFetcher = async (url: string) => {
  const response = await fetch(url);
  await validateResponse(response);
  return response.json();
};
