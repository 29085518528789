import './TabSkipLinks.scss';
import { focusCurrentTab } from 'components/sduiTabbedGrid/SduiTabbedGrid.helpers';

const TabSkipLinks = () => (
  <nav className="tab_skip_links">
    <button type="button" onClick={() => focusCurrentTab('tab_list')}>
      Back to Tabs
    </button>
  </nav>
);

export default TabSkipLinks;
