import { Hero } from 'common/types/graphQlTypes';
import SduiHeroElement from 'components/sduiHeroElement/SduiHeroElement';
import SduiImage from 'components/sduiImage/SduiImage';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const SduiHero = ({ hero }: { hero: Hero }) => (
  <StyledHero data-testid="hero" aria-live="assertive" id="hero">
    <AnimatePresence>
      <AnimatedContent
        /* This is needed to animate the Hero component when the data changes. */
        key={JSON.stringify(hero)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        /* This intentionally lacks an exit animation to make the span disappear instantly. */
        transition={{ duration: 0.3 }}
      >
        {hero.elements.map((e) => (
          <SduiHeroElement key={e.id} heroElement={e} />
        ))}
        {hero.background?.image && <SduiImage imageType={hero.background.image} sizes="100vw" />}
      </AnimatedContent>
    </AnimatePresence>
  </StyledHero>
);

export default SduiHero;

const StyledHero = styled(motion.section)`
  background: linear-gradient(to top, var(--gradient-black-bezier)) top / 100% 75% no-repeat,
    linear-gradient(to top, var(--almost-black), var(--almost-black) 26%, transparent 26%) top / 100% 100%
      no-repeat;
  padding: 15rem 9vw 0 9vw;
  position: relative;
  transition: min-height 0.3s ease-out;

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    background: unset;
    display: flex;
    padding: var(--space-content-large);
  }
`;

const AnimatedContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    text-align: left;
    align-items: flex-start;
    justify-content: center;
  }

  /* When the content changes, AnimatePresence will render both the old content and new. */
  /* This CSS forces the new content to sit on top of the old as they both animate. */
  + div {
    position: absolute;
    z-index: var(--z-index--header_and_background_container--new_hero_content);
    top: 0;
  }
`;
