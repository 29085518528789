import GeolocationLink from 'components/GeolocationLink/GeolocationLink';
import { TabbedGrid } from 'common/types/graphQlTypes';
import SduiTab from 'components/sduiTab/SduiTab';
import { Tab, TabPanel } from 'components/sduiTabbedGrid/components';
import { useAtomValue } from 'jotai';
import { headerAtom } from 'src/store/store';
import { StyledIcon } from 'styles/styledComponents';
import ChevronLeft from 'public/assets/icons/chevron-left.svg';
import ChevronRight from 'public/assets/icons/chevron-right.svg';
import { useRef, useState } from 'react';
import './SduiTabbedGrid.scss';
import classNames from 'classnames';
import { handleSliderButtonClick, handleTabClick } from './SduiTabbedGrid.helpers';
import useTabListObserver from './hooks/useTabListObserver';
import useScrollOnStop from './hooks/useScrollOnStop';
import useSelectTabInView from './hooks/useSelectTabInView';
import { TabListScrollDirection } from './SduiTabbedGrid.types';
import useScrollToTabOnLoad from './hooks/useScrollToTabOnLoad';
import TabSkipLinks from './components/TabSkipLinks/TabSkipLinks';

const SduiTabbedGrid = ({ tabbedGrid }: { tabbedGrid: TabbedGrid }) => {
  const { tabs } = tabbedGrid;
  const { headerHeight } = useAtomValue(headerAtom);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [isTabbedGridOpaque, setIsTabbedGridOpaque] = useState(false);
  const [showPrevSliderButton, setShowPrevSliderButton] = useState<boolean>(false);
  const [showNextSliderButton, setShowNextSliderButton] = useState<boolean>(true);

  const tabsRef = useRef<HTMLDivElement>(null);
  const tabListRef = useRef<HTMLUListElement>(null);

  useTabListObserver(tabListRef);
  useScrollOnStop(tabListRef, setShowPrevSliderButton, setShowNextSliderButton);
  useSelectTabInView(tabsRef, tabListRef, setSelectedTab, setIsTabbedGridOpaque);
  useScrollToTabOnLoad(tabsRef, tabListRef, headerHeight);

  return (
    <section
      aria-live="off"
      className="tabbed_grid"
      data-testid="tabbed-grid"
      id="catalogue"
      onBlur={() => window.scrollY === 0 && setIsTabbedGridOpaque(false)}
      onFocus={() => setIsTabbedGridOpaque(true)}
      onMouseOut={() => window.scrollY === 0 && setIsTabbedGridOpaque(false)}
      onMouseOver={() => setIsTabbedGridOpaque(true)}
    >
      <div
        className={classNames('tab_list__wrapper', { 'tab_list__wrapper--opaque': isTabbedGridOpaque })}
        style={{ '--header-height': `${headerHeight}px` } as React.CSSProperties}
      >
        <GeolocationLink className="geolocation_link--tabbed_grid" />
        <ul
          aria-label="List of Tabs"
          className={classNames('tab_list', {
            'tab_list--fade_left': showPrevSliderButton,
            'tab_list--fade_right': showNextSliderButton,
          })}
          id="tab_list"
          ref={tabListRef}
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <Tab
              hasScrolled={isTabbedGridOpaque}
              isSelected={selectedTab === index}
              key={index}
              onClick={() => handleTabClick(tabsRef, tabListRef, tab.title, headerHeight)}
              tabsRef={tabsRef}
              title={tab.title}
            />
          ))}
        </ul>
        {(['left', 'right'] as TabListScrollDirection[]).map((direction) => (
          <span
            className={classNames(`scroll_arrow__wrapper scroll_arrow__wrapper--${direction}`, {
              'scroll_arrow__wrapper--visible':
                direction === 'left' ? showPrevSliderButton : showNextSliderButton,
            })}
            key={direction}
          >
            <button
              className={`scroll_arrow ${direction}`}
              onClick={() => {
                handleSliderButtonClick(tabListRef, direction);
              }}
              aria-label={`Scroll ${direction}`}
              type="button"
              tabIndex={-1}
            >
              <StyledIcon $svgWidth="1em" $svgHeight="1em">
                {direction === 'left' && <ChevronLeft role="img" aria-label="Left" />}
                {direction === 'right' && <ChevronRight role="img" aria-label="Right" />}
              </StyledIcon>
            </button>
          </span>
        ))}
      </div>
      <div className="tab_panel__wrapper" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <TabPanel
            className={classNames('tab_panel', { 'tab_panel--opaque': isTabbedGridOpaque })}
            key={index}
            title={tab.title}
          >
            <SduiTab tab={tab} />
            <TabSkipLinks />
          </TabPanel>
        ))}
      </div>
    </section>
  );
};

export default SduiTabbedGrid;
