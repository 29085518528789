import { Dispatch, RefObject, useEffect } from 'react';
import { SetStateAction, useAtomValue } from 'jotai';
import { headerAtom } from 'src/store/store';
import {
  tabbedGridVerticalScrollBuffer,
  updateSelectedTab,
  getAllPanelsFromTabsElement,
  updateTabbedGridOpacityOnScroll,
} from '../SduiTabbedGrid.helpers';

const useSelectTabInView = (
  tabsRef: RefObject<HTMLElement>,
  tabListRef: RefObject<HTMLUListElement>,
  setSelectedTab: Dispatch<SetStateAction<number>>,
  setIsTabbedGridOpaque: Dispatch<SetStateAction<boolean>>
) => {
  const { headerHeight } = useAtomValue(headerAtom);

  // Update which tab is selected based on which panel is considered 'in view'.
  useEffect(() => {
    const tabsElement = tabsRef.current;
    const tabListElement = tabListRef.current;

    if (!tabsElement || !tabListElement) return;

    document.onscroll = () => {
      updateTabbedGridOpacityOnScroll(setIsTabbedGridOpaque);

      // Add a changeable buffer 'tabbedGridVerticalScrollBuffer' to this value to move the scroll position below the header background gradient.
      // 5px of extra space is added here to ensure that the tab that is auto scrolled to is considered 'in view' on all browsers.
      const tabListBottomPosition =
        headerHeight + tabListElement.clientHeight + tabbedGridVerticalScrollBuffer + 5;

      const tabPanels = getAllPanelsFromTabsElement(tabsElement);

      updateSelectedTab(tabPanels, tabListBottomPosition, setSelectedTab);
    };
  }, [headerHeight, setIsTabbedGridOpaque, tabListRef, tabsRef, setSelectedTab]);
};

export default useSelectTabInView;
