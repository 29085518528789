import { TitleElement } from 'common/types/graphQlTypes';
import SduiImage from 'components/sduiImage/SduiImage';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export type SduiTitleElementType = {
  title: TitleElement;
  element?: 'h2' | 'h3';
  className?: string;
};

const SduiTitleElement = ({ title: { logo, text }, element = 'h2', className }: SduiTitleElementType) => {
  const ImageHeading = element === 'h2' ? TitleImageH2 : TitleImageH3;
  const TextHeading = element === 'h2' ? TitleTextH2 : TitleTextH3;
  return logo ? (
    <ImageHeading className={className}>
      <StyledSduiImage imageType={logo} alt={text} sizes="50vw" />
    </ImageHeading>
  ) : (
    <TextHeading className={className} element={element}>
      {text}
    </TextHeading>
  );
};

export default SduiTitleElement;

const StyledSduiImage = styled(SduiImage)`
  max-height: calc(280px / 3);
  // According to the design, the logo should have an aspect ratio of 3/1,
  // but round and square logos got deformed by this, we are dividing by 3 the max-height of the logo to maintain the aspect ratio and not get deform.
  max-width: 280px;

  ${up('tabletPortrait')} {
    max-height: calc(320px / 3);
    max-width: 320px;
  }

  ${up('desktop')} {
    max-height: calc(384px / 3);
    max-width: 384px;
  }

  ${up('desktopXLarge')} {
    max-height: calc(22.5rem / 3);
    max-width: 22.5rem;
  }
`;

const ImageMixin = css`
  height: auto;
  max-width: 280px;
  aspect-ratio: 3 / 1;
  margin: var(--space-stack-small);

  ${up('tabletPortrait')} {
    max-width: 320px;
  }

  ${up('desktop')} {
    max-width: 384px;
  }

  ${up('desktopXLarge')} {
    max-width: 22.5rem;
  }
`;

const TitleImageH2 = styled.h2`
  ${ImageMixin}
`;

const TitleImageH3 = styled.h3`
  ${ImageMixin}
`;

const TitleTextMixin = css<{ element: 'h2' | 'h3' }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--almost-white);
  font-size: ${(props) => (props.element === 'h2' ? 'var(--h2-size)' : 'var(--h3-size)')};
  line-height: 1.3;
  max-width: 80vw;
  margin: var(--space-stack-small);

  ${up('tabletLandscape')} {
    max-width: 38vw;
  }
`;

const TitleTextH2 = styled.h2`
  ${TitleTextMixin}
`;

const TitleTextH3 = styled.h3`
  ${TitleTextMixin}
`;
