import { TitleElement } from 'common/types/graphQlTypes';
import SduiImage from 'components/sduiImage/SduiImage';
import { up } from 'styled-breakpoints';
import styled, { css } from 'styled-components';

export type SduiCardTitleElementType = {
  title: TitleElement;
  element?: 'h2' | 'h3';
  className?: string;
};

const SduiCardTitleElement = ({
  title: { logo, text },
  element = 'h2',
  className,
}: SduiCardTitleElementType) => {
  const ImageHeadingLevel = element === 'h2' ? 2 : 3;
  const TextHeading = element === 'h2' ? TitleTextH2 : TitleTextH3;
  return logo ? (
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/heading_role
    <div className={className} role="heading" aria-level={ImageHeadingLevel} aria-label={text}>
      <StyledSduiImage imageType={logo} alt={text} sizes="50vw" />
    </div>
  ) : (
    <TextHeading className={className} element={element}>
      {text}
    </TextHeading>
  );
};

export default SduiCardTitleElement;

const StyledSduiImage = styled(SduiImage)`
  max-width: 13.71rem;
  max-height: 4.57rem;
  margin: var(--space-stack-small);

  ${up('tabletPortrait')} {
    max-width: 14.25rem;
    max-height: 4.75rem;
  }

  ${up('desktop')} {
    max-width: 10rem;
    max-height: 3.333rem;
  }
`;

const TitleTextMixin = css<{ element: 'h2' | 'h3' }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: var(--almost-white);
  font-size: ${(props) => (props.element === 'h2' ? 'var(--h2-size)' : 'var(--h3-size)')};
  line-height: 1.3;
  max-width: 80vw;
  margin: var(--space-stack-small);

  ${up('tabletLandscape')} {
    max-width: 38vw;
  }
`;

const TitleTextH2 = styled.h2`
  ${TitleTextMixin}
`;

const TitleTextH3 = styled.h3`
  ${TitleTextMixin}
`;
