import { up } from 'styled-breakpoints';
import { css } from 'styled-components';

export const headerAndBackgroundContainerMixin = () => css`
  /* This is following design guidelines https://nine.atlassian.net/wiki/spaces/9NOW/pages/9007923225/Video+component */
  align-items: flex-end;
  aspect-ratio: 4/7;
  display: flex;
  max-height: 700px;
  min-height: 600px;
  position: relative;
  width: 100%;
  z-index: var(--z-index--header_and_background_container);
  margin-top: calc(var(--header-height-tablet) * -1);

  ${up('tabletPortrait')} {
    aspect-ratio: unset;
    height: 90vh;
    align-self: flex-end;
    max-height: unset;
  }

  // On widths between 900px and 999px, if the viewport is landscape, the Hero should be centred
  // This media query is equivalent to up('tabletLandscape') but excluding the case above
  @media (min-width: 900px) and (max-width: 999px) and (orientation: portrait), (min-width: 1000px) {
    align-items: center;
    max-height: 1200px;
  }

  ${up('desktop')} {
    aspect-ratio: 16/9;
    height: unset;
    max-height: unset;
    margin-top: calc(var(--header-height-desktop) * -1);
  }
`;

export const headerOverlayMixin = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;

  ${up('tabletLandscape')} {
    align-items: center;
  }
`;

export const nineNowBackgroundGradientMixin = () => css`
  background: linear-gradient(
    180deg,
    var(--gradient-turquoise-top) 0%,
    var(--gradient-turquoise-bottom) 100%
  );
  color: var(--almost-black);
`;
